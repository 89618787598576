import Card from '@components/partials/common/card/card';
import styles from '@styles/common-scss/card/_card.module.scss';
import Paginationstyles from '@styles/common-scss/navbar/_pagination.module.scss';
import cardListstyles from '@styles/default-page-scss/list-cards/_list.module.scss';
import algoliasearch from 'algoliasearch';
import React, { useEffect, useRef, useState } from 'react';
import { Configure, Hits, InstantSearch, SearchBox, useInstantSearch, usePagination } from 'react-instantsearch';

import { formatAlgoliaResults } from '../../../../../lib/repository/algoliaFormatConverter';
import SvgX from '../../../../svgs/svgBlackX';

const algoliaClient = algoliasearch(process.env.NEXT_PUBLIC_ALGOLIA_APPLICATION_ID, process.env.NEXT_PUBLIC_ALGOLIA_PASSWORD);

const searchClient = {
    ...algoliaClient,
    search(requests) {
        // Filter out requests with queries less than 3 characters
        const validRequests = requests.filter((request) => request.params && request.params.query.length > 2);
        return validRequests.length > 0
            ? algoliaClient.search(validRequests)
            : Promise.resolve({
                  results: requests.map(() => ({
                      hits: [],
                      nbHits: 0,
                      hitsPerPage: 0,
                      nbPages: 1,
                      processingTimeMS: 0
                  }))
              });
    }
};

// https://discourse.algolia.com/t/preventing-empty-search-query-in-react-hooks-typescript/18105/2
import { history } from 'instantsearch.js/es/lib/routers';
import Link from 'next/link';

/**
 * Custom hook to detect clicks outside of a given ref.
 * @param ref - The ref to check for outside clicks.
 * @param onClickOutside - Function to call when a click is detected outside the ref.
 * @param ignoreRef - Optional ref to ignore when checking for outside clicks.
 */
const useClickOutside = (ref, onClickOutside, ignoreRef = { current: null }) => {
    const events = ['mousedown', 'touchstart'];

    useEffect(() => {
        const isOutside = (element) =>
            (!ref.current || !ref.current.contains(element)) && (!ignoreRef.current || !ignoreRef.current.contains(element));

        const onClick = (event) => {
            if (isOutside(event.target)) {
                onClickOutside();
            }
        };

        for (const event of events) {
            document.addEventListener(event, onClick);
        }

        return () => {
            for (const event of events) {
                document.removeEventListener(event, onClick);
            }
        };
    }, [ref, onClickOutside, ignoreRef]);
};

function Search() {
    const rootRef = useRef();
    const searchResutlRef = useRef(null);
    const [searchVisible, setSearchVisible] = useState(true);
    const ignoreRef = useRef(null);

    return (
        <div ref={rootRef} onClick={() => setSearchVisible(true)} onKeyDown={() => setSearchVisible(true)} role="presentation">
            <InstantSearch
                searchClient={searchClient}
                indexName="RRX"
                insights
                future={{ preserveSharedStateOnUnmount: true, cleanUrlOnDispose: false }} //@TODO Fix if upgrade
            >
                <Configure hitsPerPage={24} />
                <Header />
                {searchVisible && (
                    <Content
                        rootRef={rootRef}
                        searchResutlRef={searchResutlRef}
                        ignoreRef={ignoreRef}
                        setSearchVisible={setSearchVisible}
                    />
                )}
            </InstantSearch>
        </div>
    );
}
/**
 * Pagination component for search results.
 * @param props - Props passed from the parent component.
 */
function Pagination(props) {
    const { pages, currentRefinement, nbPages, refine } = usePagination(props);
    useEffect(() => {
        props.searchResutlRef.current?.scrollTo(0, 0);
    }, [pages]);

    return (
        <>
            {nbPages ? (
                <div className={Paginationstyles.paginationContainer}>
                    <ul className={Paginationstyles.paginationList}>
                        {currentRefinement === 0 ? (
                            ''
                        ) : (
                            <>
                                <li className={Paginationstyles.pageItem}>
                                    <a
                                        href="#"
                                        onClick={(event) => {
                                            event.preventDefault();
                                            refine(0);
                                        }}>
                                        {`<<`}
                                    </a>
                                </li>
                                <li className={Paginationstyles.pageItem}>
                                    <a
                                        href="#"
                                        onClick={(event) => {
                                            event.preventDefault();
                                            refine(currentRefinement - 1);
                                        }}>
                                        {`<`}
                                    </a>
                                </li>
                            </>
                        )}
                        {pages.map((page) => (
                            <li
                                key={page}
                                className={`${Paginationstyles.pageItem} ${currentRefinement === page ? Paginationstyles.selected : ''}`}>
                                <a
                                    href="#"
                                    onClick={(event) => {
                                        event.preventDefault();
                                        refine(page);
                                    }}>
                                    {page + 1}
                                </a>
                            </li>
                        ))}
                        {currentRefinement + 1 === nbPages ? (
                            ''
                        ) : (
                            <>
                                <li className={Paginationstyles.pageItem}>
                                    <a
                                        href="#"
                                        onClick={(event) => {
                                            event.preventDefault();
                                            refine(currentRefinement + 1);
                                        }}>
                                        {`>`}
                                    </a>
                                </li>
                                <li className={Paginationstyles.pageItem}>
                                    <a
                                        href="#"
                                        onClick={(event) => {
                                            event.preventDefault();
                                            refine(nbPages - 1);
                                        }}>
                                        {`>>`}
                                    </a>
                                </li>
                            </>
                        )}
                    </ul>
                </div>
            ) : (
                ''
            )}
        </>
    );
}

const Header = () => (
    <>
        <SearchBox
            placeholder={'Search'}
            classNames={{
                root: 'search-bar',
                form: 'search-form'
            }}
            onClick={() => (document.documentElement.style.overflowY = 'hidden')}
            searchAsYouType={true}
            autoFocus={false} // eslint-disable-line
            resetIconComponent={({ classNames }) => {
                document.documentElement.style.overflowY = '';

                return (
                    <div className={'resettIcon'}>
                        <SvgX color="black" />
                    </div>
                );
            }}
            submitIconComponent={({ classNames }) => (
                <div className={'submitIcon'}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                        <path
                            d="M13.75 23.75C19.2729 23.75 23.75 19.2728 23.75 13.75C23.75 8.22715 19.2729 3.75 13.75 3.75C8.22718 3.75 3.75003 8.22715 3.75003 13.75C3.75003 19.2728 8.22718 23.75 13.75 23.75Z"
                            strokeWidth="3"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path d="M26.25 26.25L20.8125 20.8125" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </div>
            )}
        />
    </>
);
/**
 * Custom Hit component for rendering search results.
 * @param props - Props passed from the parent component.
 */
const HitComponent = (props) => {
    const hits = formatAlgoliaResults(props.hit);

    //turning the hits into the structure the cards want
    const holiday = {
        name: '',
        color: '',
        path: '',
        promoLabel: ''
    };
    // https://www.algolia.com/doc/api-reference/widgets/search-box/vue/#widget-param-class-names
    return (
        <>
            <div className={`${cardListstyles.cardList} ${styles.searchCardList}`}>
                <Card
                    site={hits}
                    holiday={holiday}
                    pageTag={'search-results'}
                    index={hits.siteItemProps.id}
                    eventSender={props.sendEvent}
                    eventData={props.hit}
                />
            </div>
        </>
    );
};

/**
 * Boundary component to handle no results scenarios.
 * @param children - The children components to render.
 * @param fallback - The fallback component to render when there are no results.
 */
const NoResultsBoundary = ({ children, fallback }) => {
    const { results } = useInstantSearch();

    if (!results || (!results.__isArtificial && results.nbHits === 0)) {
        return (
            <>
                {fallback}
                {/* <div hidden>{children}</div> */}
            </>
        );
    }

    return children;
};

/**
 * Component to render when there are no search results.
 */
const NoResults = () => {
    const { indexUiState } = useInstantSearch();

    return (
        <div className="search-overlay">
            <div className="search-message">
                We could not find a result for <q>{indexUiState.query}</q>. Please,{' '}
                <Link href="/customer-support/contact-us">
                    <strong>
                        <a>Email Us</a>
                    </strong>
                </Link>{' '}
                if you are looking for a specific type of site, or check out our{' '}
                <strong>
                    <a
                        role="button"
                        tabIndex={0}
                        onClick={() => (window.location.href = '/')}
                        onKeyDown={(e) => e.key === 'Enter' && (window.location.href = '/')}>
                        Latest Reviews
                    </a>
                </strong>
                .
            </div>
        </div>
    );
};

/**
 * Content component for the search overlay.
 * @param rootRef - The ref for the root element.
 * @param searchResutlRef - The ref for the search results element.
 * @param ignoreRef - The ref to ignore for click outside detection.
 * @param setSearchVisible - Function to set the search visibility state.
 */
const Content = ({ rootRef, searchResutlRef, ignoreRef, setSearchVisible }) => {
    useClickOutside(
        rootRef,
        () => {
            setSearchVisible(false);
        },
        ignoreRef
    );
    const { indexUiState } = useInstantSearch();
    return indexUiState.query && String(indexUiState.query).length > 2 ? (
        <>
            <NoResultsBoundary fallback={<NoResults />}>
                <div ref={searchResutlRef} className="search-overlay ">
                    <Hits className={cardListstyles.allCards} hitComponent={HitComponent} sendEvent />
                    <Pagination searchResutlRef={searchResutlRef} />
                </div>
            </NoResultsBoundary>
        </>
    ) : (
        ''
    );
};
export default Search;
