// Importing required modules and types
import { useOnClickOutside } from '@components/partials/common/handleOutsideClick';
import ChevronDown from '@components/svgs/svgChevronDown';
import ChevronUp from '@components/svgs/svgChevronUp';
import style from '@styles/common-scss/navbar/hamburger-menu/_menu.module.scss';
import Link from 'next/link';
import { useEffect, useState } from 'react';

import { MyContext } from '../../../../../../context/provider';
import { menuParser } from '../../../../../../lib/repository/menuParser';
import { MenuType } from '../../../../../../lib/types/menuProps';

// Menu component that displays a list of menu items
const Menu = ({ menuRef, setOpen }: any): JSX.Element => {
    // Parsing the menu items from the context
    const { menu, theme } = MyContext();
    const [menuDict, setMenuDict] = useState<[MenuType, MenuType[]][]>(menuParser(menu, theme, []));

    useOnClickOutside(menuRef, () => setOpen(false));

    useEffect(() => {
        if (typeof window !== 'undefined') {
            // Read from sessionStorage
            const storedMenuState = JSON.parse(sessionStorage.getItem('menuItems') || '[]');
            const parsedMenu = menuParser(menu, theme, storedMenuState);
            setMenuDict(parsedMenu);
        }
    }, [menu, theme]);

    useEffect(() => {
        if (typeof window !== 'undefined') {
            sessionStorage.setItem('menuItems', JSON.stringify(menuDict.map(([item]) => [item.linkText, item.subsOpen])));
        }
    }, [menuDict]);

    const toggleMenuItem = (topLevelItemIndex: number) => {
        const newMenuDict = [...menuDict];
        menuDict[topLevelItemIndex][0].subsOpen = !menuDict[topLevelItemIndex][0].subsOpen;
        setMenuDict(newMenuDict);
    };
    return (
        <>
            <div className={style.wrapper} aria-label="Navigation button">
                <ul className={style.menu}>
                    {menuDict?.map((menuItem: [MenuType, MenuType[]], index: number) => (
                        <>
                            {/* // Checking if the menu item is a link to an external page */}
                            <li className={`${style.menuItem}`} key={`menu-item-${index}`}>
                                <a
                                    href={process.env.NEXT_PUBLIC_APP_DOMAIN + menuItem[0].linkSlug}
                                    {...(menuItem[0].linkRel ? { rel: menuItem[0].linkRel } : {})}
                                    title={menuItem[0].linkText}
                                    className={style.menuItemAnchor}
                                    data-event-category="User Action"
                                    data-event-action="Menu Bar"
                                    data-event-label={menuItem[0].linkText}
                                    style={{ color: menuItem[0].linkColor ? menuItem[0].linkColor : '' }}>
                                    {menuItem[0].linkText.toUpperCase()}
                                </a>
                                {menuItem[1].length > 0 ? (
                                    <button className={style.toggleSubMenu} onClick={() => toggleMenuItem(index)}>
                                        {menuItem[0].subsOpen ? (
                                            <ChevronUp color={`var(--color-000-f5f)`} height={12} width={12} />
                                        ) : (
                                            <ChevronDown color={`var(--color-000-f5f)`} height={12} width={12} />
                                        )}
                                    </button>
                                ) : (
                                    ''
                                )}
                            </li>

                            <ul
                                className={`${style.subMenu} ${menuItem[1].length > 0 && menuItem[0].subsOpen ? '' : style.hidden}`}>
                                {menuItem[1]?.map((submenuItem: any, subindex: number) => (
                                    <li className={`${style.submenuItem}`} key={`sub-menu-item-${subindex}`}>
                                        <a
                                            href={process.env.NEXT_PUBLIC_APP_DOMAIN + submenuItem.linkSlug}
                                            {...(submenuItem.linkRel ? { rel: submenuItem.linkRel } : {})}
                                            title={submenuItem.linkText}
                                            className={style.menuItemAnchor}
                                            data-event-category="User Action"
                                            data-event-action="Menu Bar"
                                            data-event-label={submenuItem.linkText}
                                            style={{ color: submenuItem.linkColor ? submenuItem.linkColor : '' }}>
                                            {submenuItem.linkText.toUpperCase()}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </>
                    ))}
                </ul>
            </div>
        </>
    );
};

export default Menu;
