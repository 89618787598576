/**
 * The Chevron Down icon
 * @returns SVG
 */

type Props = { color: string; height?: number; width?: number };
export default function ChevronUp({ color, height, width }: Props): JSX.Element {
    const isHeight = height ? height : 9;
    const isWidth = width ? width : 10;
    return (
        <>
            <svg width={isWidth} height={isHeight} viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14 8L8 2L2 8" stroke={color} strokeWidth="2.13333" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        </>
    );
}
