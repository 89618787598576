import Footer from '@components/partials/common/footer';
import Loger from '@components/partials/common/loger';
import MIDcookie from '@components/partials/common/MIDcookie';

export default function Base({ children }: { children: React.ReactNode }): JSX.Element {
    return (
        <>
            <MIDcookie />
            {children}
            <Footer />
            <Loger />
        </>
    );
}
